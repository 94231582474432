import Checkbox from "app/layout/Checkbox";
import { MDBBtn } from "mdbreact";
import React, { useState, useMemo } from "react";
import { IFilter, IOption } from "../../utils/utils";

type FilterValue =
  | IFilter["ffi_collection"]
  | IFilter["ffi_collection"]
  | IFilter["ffifabrics_grade"]
  | IFilter["ffifabrics_category1"]
  | IFilter["ffifabrics_hexclrgrp"]
  | IFilter["product_features"]
  | IFilter["ffi_product_type_filter"];

export interface FilterListProps<FV extends FilterValue> {
  options: IOption[];
  filterValue: FV;
  setFilterValue: (value: FilterValue) => void;
  onSaveSelection: () => void;
  search?: React.ReactNode;
  isCategory?: boolean;
  setSelectedCategory?: (value: any) => void;
  sideFilter: boolean;
}

const FilterList = <FV extends FilterValue>(props: FilterListProps<FV>) => {
  const [loadMore, setLoadMore] = useState(false);

  if (
    props.options?.length &&
    !props.options?.find((option) => option.label === "All")
  ) {
    props.options.unshift({ label: "All", value: "" });
  }

  const checkboxData = useMemo(() => {
    return loadMore ? props.options : props.options.slice(0, 10);
  }, [loadMore, props.options]);

  return (
    <>
      <div className="my-4 flex-grow-1 overflow-auto">
        {props.search}
        {checkboxData.map((option) => {
          const isAll = !option.value;
          const checked = isAll
            ? props.filterValue.length === 0
            : props.filterValue.includes(option.value);
          return (
            <Checkbox
              className="px-xl-0 py-xl-3 p-4"
              key={option.value}
              checked={checked}
              label={option.label}
              onChange={() => {
                if (props?.isCategory) {
                  // @ts-ignore
                  if (option.label === "All") {
                    // @ts-ignore
                    // props.setSelectedCategory(null);
                    // @ts-ignore
                    props.setFilterValue(null);
                  } else {
                    // @ts-ignore
                    // props?.setSelectedCategory(option);
                    // @ts-ignore
                    props.setFilterValue(option);
                  }
                } else {
                  props.setFilterValue(
                    isAll
                      ? []
                      : checked
                      ? props.filterValue.filter(
                          (value) => value !== option.value
                        )
                      : props.filterValue.concat(option.value)
                  );
                }
              }}
            />
          );
        })}
        {props.options.length > 10 && !loadMore && (
          <button
            style={{ background: "none", border: "none", color: "#404040" }}
            className="fs-16 underline"
            onClick={() => setLoadMore(true)}
          >
            Load More
          </button>
        )}
      </div>
      {!props.sideFilter && (
        <div className="m-4 p-2">
          <MDBBtn className="m-0 w-100" onClick={props.onSaveSelection}>
            Save Selection
          </MDBBtn>
        </div>
      )}
    </>
  );
};

export default FilterList;
