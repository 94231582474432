import heart from "app/assets/icon/favoriteEmpty.svg";
import heartFull from "app/assets/icon/favoriteActive.svg";
import newBadge from "app/assets/icon/newBadge.svg";
import view3d from "app/assets/icon/view3d.svg";
import { createEnterHandler, pathnameIncludes } from "app/utils/utils";
import Button from "core/components/button";
import Price from "core/components/price";
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useState, useContext, useMemo, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import Image from "app/layout/image";
import { useBoolean } from "ahooks";
import fabrics from "generation/fabrics";
import {
  useCreationWishlistForm,
  useWishlists,
  useToggleWishlistItem,
} from "app/pages/collections/hooks";
import WishlistsModal from "app/pages/collections/components/WishlistsModal/WishlistsModal";
import CollectionsContext from "../collections/CollectionsContext";
import { defaultOptions } from "./defaultOptions";
import { isEqual } from "lodash";

const ProductWidget = ({
  image = { url: "http://via.placeholder.com/800", label: "placeholder" },
  sku = "Product Widget",
  name = "Placeholder Product Name",
  colSize = 3,
  mdSize = colSize,
  lgSize = mdSize,
  url_key = "#",
  url = url_key,
  className,
  onClick,
  isFavorite,
  is_new,
  onToggleFavorite,
  badges,
  price,
  showFavorites = true,
  isBuildYourOwn,
  isFinish = false,
  grade = false,
  isSavedBuild = false,
  savedBuildTitle = "",
  product,
  items,
  isFullHeart,
  thumbnail = { url: "http://via.placeholder.com/800", label: "placeholder" },
  category,
  formattedOptions,
  categoryName,
  isSearchPage,
}) => {
  // const showInfo =
  //   ["Fabrics", "Finish"].includes(product?.attribute_set_name) ||
  //   window.location.search.includes("fabrics-and-leather");

  const findProductBySku = (sku) => items?.find((item) => item.sku === sku);
  let options = useMemo(() => {
    let product = findProductBySku(sku);
    if (!product?.options) return {};
    return defaultOptions(product);
  }, [items]);

  const isMonogram = useMemo(() => {
    let itemBySku = findProductBySku(sku);
    if (itemBySku?.ffi_collection?.includes("Monogram")) return true;
    return false;
  }, [items]);

  const isFabricsSearch = useMemo(
    () => isSearchPage && categoryName === "fabrics-and-leather",
    [categoryName, isSearchPage]
  );

  const getPreselectedOptionString = (options) => {
    let stringArr = [];
    for (const key in options) {
      if (options[key]) stringArr.push(`${key}=${options[key]}`);
    }
    return stringArr?.join("&");
  };

  const findFabric = useMemo(() => {
    let fabricObj = product?.selected_customizable_options?.find(
      (option) => option?.option_title === "Main Fabric"
    );
    let fabricName = fabrics
      .find((fabric) => fabric?.name === "Fabric")
      ?.products?.items?.find(
        (item) => item?.sku === fabricObj?.option_value_title
      )?.name;
    return fabricName;
  }, [product]);

  const location = useLocation();
  const { setSelectedProduct, setShowModal, setIsFavorite } = useContext(
    CollectionsContext
  );

  const calculateOptions = (currentProduct, optionsWithValues) => {
    let foundOptions = [];
    for (let i = 0; i < currentProduct?.options?.length; i++) {
      const element = currentProduct?.options[i];
      for (const key in optionsWithValues) {
        if (element?.title?.toLowerCase()?.split(" ")?.join("_") === key)
          foundOptions.push({
            id: element?.option_id,
            value_string: optionsWithValues[key],
          });
      }
    }
    const buildUrlId = currentProduct?.options?.find(
      (option) => option.title === "Build your own configurator url"
    )?.option_id;

    foundOptions.push({
      id: buildUrlId,
      value_string: `/${currentProduct?.url_key}?${getPreselectedOptionString(
        options
      )}`,
    });
    return foundOptions;
  };

  const creationWishlistForm = useCreationWishlistForm();
  const wishlists = useWishlists();
  const toggleWishlistItem = useToggleWishlistItem();
  const [formShown, setFormShown] = useBoolean(true);
  const [isShownWhislist, setisShownWhislist] = useState(false);
  const [productSelected] = useState(null);

  let checkIfFavoriteByPreselectedOptions = useMemo(() => {
    if (!Object.keys(options)?.length) return true;
    const myFavorites = wishlists?.find(
      (wishlist) => wishlist.name === "My Favorites"
    );
    const productsInWishlist = myFavorites?.items?.filter(
      (item) => item?.product?.sku === sku
    );

    for (let i = 0; i < productsInWishlist?.length; i++) {
      const element = productsInWishlist[i];
      let configUrl = element?.selected_customizable_options
        ?.find(
          (option) => option.option_title === "Build your own configurator url"
        )
        ?.option_value_title?.split("?")?.[1];

      if (isEqual(configUrl, getPreselectedOptionString(options))) {
        return true;
      }
    }
  }, [options, wishlists]);

  const clean = (obj) => {
    for (var propName in obj) {
      if (!obj[propName]) delete obj[propName];
    }
    return obj;
  };

  const handleFavorite = React.useCallback(
    (e, sku) => {
      setSelectedProduct(null);
      e.preventDefault();
      setIsFavorite(true);

      const isBYO = product?.selected_customizable_options?.some((option) =>
        option?.option_title?.includes("Build your own image")
      );

      if (!product?.product && !isBYO) {
        let optionsWithValues = clean(options);
        let currentProduct = findProductBySku(sku);
        if (
          currentProduct.options?.length > 1 &&
          currentProduct.options?.find(
            (option) => option?.title === "Build your own configurator url"
          )
        ) {
          currentProduct.options = calculateOptions(
            currentProduct,
            optionsWithValues
          );
        }
        const isItemInProject = !currentProduct?.options
          ? true
          : wishlists
              .filter((wishlist) => wishlist.name !== "My Favorites")
              ?.find((project) =>
                project.items?.some(
                  (item) =>
                    item?.product?.sku === currentProduct?.sku &&
                    currentProduct?.options[currentProduct?.options?.length - 1]
                      ?.value_string ===
                      item?.selected_customizable_options[
                        item?.selected_customizable_options?.length - 1
                      ]?.option_value_title
                )
              );

        if (wishlists) {
          let arr = [];
          wishlists.map((wishlist) =>
            wishlist.items?.filter((wishistitem) => {
              if (wishistitem?.product?.sku === currentProduct?.sku) {
                arr.push(wishistitem);
                return wishistitem;
              }
            })
          );

          if (isFavorite && arr.length > 1 && isItemInProject) {
            setisShownWhislist(true);
            setShowModal(true);
            setSelectedProduct(currentProduct);
          } else {
            onToggleFavorite();
            setShowModal(true);
          }
          return;
        }
      } else {
        if (isBYO) {
          setSelectedProduct(product);
          setShowModal(true);
        } else {
          if (product?.product) {
            product.product.defaultOptions =
              product?.selected_customizable_options;
            product.product.productId = product?.id;
          }
          setSelectedProduct(product?.product);
          setShowModal(true);
        }
        setisShownWhislist(true);
      }
    },
    [onToggleFavorite, wishlists, options]
  );

  const item = items?.find((item) => item.sku === sku);
  const urlLink = useMemo(() => {
    let link =
      typeof url !== "object"
        ? `/${
            typeof item?.ffi_collection !== "undefined" &&
            item?.ffi_collection?.[0] !== false &&
            item?.ffi_cfgmodel
              ? item?.ffi_collection?.includes("Haven Reserve")
                ? `build-your-own/haven-reserve/${sku}`
                : item?.ffi_collection?.includes("Urban Living")
                ? `build-your-own/urban-living/${sku}`
                : item?.ffi_collection?.includes("Senior Living Solutions")
                ? `build-your-own/senior-living-solutions/${sku}`
                : `build-your-own/monogram/${sku}`
              : getPreselectedOptionString(options)
              ? `${url}?${getPreselectedOptionString(options)}`
              : url
          }`
        : getPreselectedOptionString(options)
        ? `${url}?${getPreselectedOptionString(options)}`
        : url;
    if (typeof link === "string") {
      link += `${link?.includes("?") ? "&" : "?"}search=true`;
      link = link.replaceAll(location.pathname, "");
    }
    return link;
  }, [item, options, location.pathname]);

  return (
    <>
      <MDBCol
        size={colSize}
        md={mdSize}
        lg={lgSize}
        className={`product-widget ${className}`.trim()}
        id={sku}
      >
        <Link
          target={
            typeof item?.ffi_collection !== "undefined" &&
            item?.ffi_collection?.[0] !== false &&
            item?.ffi_cfgmodel
              ? "_blank"
              : "_self"
          }
          to={
            typeof urlLink === "object"
              ? { pathname: urlLink?.pathname, search: urlLink?.search }
              : `${
                  isSearchPage
                    ? ""
                    : typeof item?.ffi_collection !== "undefined" &&
                      item?.ffi_collection?.[0] !== false &&
                      item?.ffi_cfgmodel
                    ? ""
                    : `${location.pathname}`
                }${urlLink}`
          }
          onClick={(e) => {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set("sku", sku);
            window.history.replaceState(
              null,
              "",
              `${window.location.pathname}?${urlParams.toString()}`
            );
            if (url === "#") e.preventDefault();
            typeof onClick === "function" && onClick(e);
          }}
          className="d-flex flex-column h-100"
        >
          <MDBRow className="flex-fill flex-center position-relative product-img-wrapper ">
            {showFavorites && !isMonogram && (
              <div
                onClick={(e) => handleFavorite(e, sku)}
                onKeyUp={createEnterHandler(handleFavorite)}
                tabIndex={0}
                role="button"
                className="favorite pointer position-absolute mt-lg-4 pt-lg-2 mr-3"
                style={{ top: "2%", right: "3%", zIndex: "2" }}
              >
                <img
                  src={
                    (isFavorite || isFullHeart) &&
                    checkIfFavoriteByPreselectedOptions
                      ? heartFull
                      : heart
                  }
                  alt="Favorite"
                />
              </div>
            )}

            <div className="position-relative w-100 d-flex justify-content-center">
              <Image
                source={
                  location.pathname.includes("fabrics-finishes/finishes")
                    ? thumbnail.url
                    : image.url
                }
                className={`product-widget-image ${
                  window.location.pathname.includes(
                    "fabrics-finishes/fabrics"
                  ) && "border-grey"
                }`}
                alt={
                  location.pathname.includes("fabrics-finishes/finishes")
                    ? thumbnail.label
                    : image.label
                }
                loaderAsSkeleton={true}
              />
              {/* <img
              src={image.url}
              className="img-fluid"
              style={{ maxHeight: "25.55rem", width: "auto" }}
              alt={image.label}
            /> */}

              {item?.ffi_cfgmodel ? (
                <div
                  className="position-absolute"
                  style={{
                    top: 0,
                    left: 0,
                  }}
                >
                  <div className="cfgmodel">
                    <img src={view3d} alt="View 3d" />
                  </div>
                </div>
              ) : null}

              {is_new ? (
                <img
                  src={newBadge}
                  alt="New"
                  className="position-absolute m-3"
                  style={{ top: 0, left: item?.ffi_cfgmodel ? 45 : 0 }}
                />
              ) : null}
            </div>
            {/* <div
              className="badges position-absolute d-flex justify-content-center mb-3 mx-auto"
              style={{ bottom: "1%", left: 0, right: 0, zIndex: 2 }}
            >
              {badges}
            </div> */}
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol className="">
              <MDBRow className={`${isFinish && "finish-fix-row "}`}>
                <MDBCol className="p-4 mb-4 mx-4 hover-bg-gray-lighten-3">
                  {product?.ffi_collection?.map((collection) =>
                    collection !== "false" ? (
                      <MDBRow key={collection} className="pb-4">
                        <MDBTypography
                          tag="h5"
                          className="w-100 text-center text-uppercase fs-12 product-collection-name"
                        >
                          {collection}
                        </MDBTypography>
                      </MDBRow>
                    ) : (
                      <></>
                    )
                  )}
                  <MDBRow className="">
                    <MDBTypography
                      tag={"h4"}
                      className={`w-100 text-center product-title ${
                        isFinish && "finish-fix-text"
                      }`}
                    >
                      {product?.ffi_name || name}
                    </MDBTypography>
                  </MDBRow>
                  {product?.short_description?.html && (
                    <MDBRow className="">
                      <MDBTypography
                        tag="h4"
                        className={`w-100 text-center product-description`}
                      >
                        {product?.short_description?.html}
                      </MDBTypography>
                    </MDBRow>
                  )}
                  {product?.ffi_extdesc2 && (
                    <MDBRow className="pt-2 pb-2">
                      <MDBTypography
                        tag="h5"
                        className={`w-100 text-center font-weight-bold `}
                      >
                        {product?.ffi_extdesc2}
                      </MDBTypography>
                    </MDBRow>
                  )}
                  {product?.ffifabrics_grade?.[0] !== "false" && (
                    <MDBRow className={`pt-2 pb-2  `}>
                      <MDBTypography
                        tag="h5"
                        className={`w-100 text-center commercial-grade`}
                      >
                        {product.ffifabrics_grade}
                      </MDBTypography>
                    </MDBRow>
                  )}
                  <MDBRow className={`pt-2 pb-2 product-name`}>
                    <MDBTypography
                      tag="h5"
                      className="w-100 text-center product-sku"
                    >
                      {sku}
                    </MDBTypography>
                  </MDBRow>

                  {findFabric && (
                    <MDBRow className={`pt-3 pb-2 product-name`}>
                      <MDBTypography tag="h5" className="w-100 text-center">
                        Main Fabric: {findFabric}
                      </MDBTypography>
                    </MDBRow>
                  )}
                  <MDBRow>
                    {isSavedBuild && (
                      <MDBCol size="12">
                        <MDBRow className="pt-3 pb-lg-4 pb-0">
                          <MDBTypography
                            tag="span"
                            className="w-100 text-center body-1"
                          >
                            {savedBuildTitle}
                          </MDBTypography>
                        </MDBRow>
                      </MDBCol>
                    )}
                  </MDBRow>
                  {price &&
                    !isFabricsSearch &&
                    !location.pathname.includes("fabrics-finishes/") &&
                    category !== "Fabrics and Leather" &&
                    !location.search.includes(
                      "category=fabrics-and-finishes"
                    ) && (
                      <MDBRow className="pt-3 pb-lg-4 pb-0">
                        <MDBTypography
                          tag="h5"
                          className="body-1 w-100 fwmedium text-center fs-14 product-price"
                        >
                          Starting at: <Price price={price} type={"fixed"} />
                        </MDBTypography>
                      </MDBRow>
                    )}
                  {grade &&
                    (category === "Fabrics and Leather" ||
                      location.pathname.includes("fabrics-finishes/fabrics/") ||
                      location.search.includes(
                        "category=fabrics-and-finishes"
                      ) ||
                      isFabricsSearch) && (
                      <MDBRow className="pt-3 pb-lg-4 pb-0">
                        <MDBTypography
                          tag="h5"
                          className="body-1 w-100 fwlight text-center"
                        >
                          Grade:{" "}
                          {grade[0] !== false && grade[0] !== "false"
                            ? grade
                            : ""}
                        </MDBTypography>
                      </MDBRow>
                    )}
                </MDBCol>
              </MDBRow>
              {isBuildYourOwn && (
                <Button className={`btn-secondary btn mt-3 mx-0 w-100`}>
                  Customize
                </Button>
              )}
            </MDBCol>
          </MDBRow>
        </Link>
      </MDBCol>
      {isShownWhislist &&
        !location.pathname.includes("customer/collections/") && (
          <WishlistsModal
            {...product}
            selectedProduct={productSelected}
            onClose={() => setisShownWhislist(false)}
            creationWishlistForm={creationWishlistForm}
            formShown={formShown}
            onShowForm={setFormShown.setTrue}
            onCheck={toggleWishlistItem}
            wishlists={wishlists}
            formattedOptions={formattedOptions}
          />
        )}
    </>
  );
};

export default React.memo(ProductWidget);
